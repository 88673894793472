body::-webkit-scrollbar {
    width: 2px;
}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(0, 0, 0);
}

body::-webkit-scrollbar-thumb {
    background-color: rgb(113, 113, 113);
}

.app {
    font-family: Avenir,Helvetica,Arial,sans-serif;
    padding-top: 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media screen and (max-width: 1010px) {
    .app {
        padding-top: 10%;
        padding-bottom: 10%;
    }
}