.container {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-box {
    max-width: 492px;
    width: 95%;
    background-color: rgb(26, 26, 28) !important;
    border-radius: 4px;
    height: fit-content;
    margin-top: 20px;
    color: rgb(180, 180, 183);
}

.return-address {
    display: flex;
    align-items: center;
    color: rgb(149, 149, 149);
    height: 45px;
    background-color: rgb(43, 43, 43);
    margin-top: 20px;
    cursor: pointer;
}

.return-address > input {
    background-color: transparent;
    cursor: pointer;
    border-style: none;
    color: inherit;
    outline: none;
    text-align: center;
    width: 100%;
    padding-left: 20px;
}

.footer-buttons {
    padding: 20px;
    padding-bottom: 0px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
}

.add-network {
    background-color: rgba(255, 255, 255, 0.15);
    color: darkgrey;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    border-radius: 25px;
    padding: 10px;
    min-width: 45%;
    height: 36px;
    border: 0px;
}

.add-network:hover {
    background-color: black;
    color: white;
}

@media screen and (max-width: 500px) {
    .footer-buttons {
        padding: 20px 20px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        gap: 0px;
    }

    .footer-buttons > button {
        margin-top: 10px;
    }
}